<template>
<cube-popup type="my-popup1" ref="ActionSheet" :zIndex='1000'>
  <div id="LayActionSheet">

    <div class="aqt_confirm_center   animate_active" :class="{ 'active': IsShow}">
      <div class=" aqt_confirm_buton">
        <div class="but ok" @click="select1">
          <p class="aqt_lang_mn titlefont option">{{option1|U2M}}</p>
        </div>
        <div class="but ok" @click="select2">
          <p class="aqt_lang_mn titlefont option">{{option2|U2M}}</p>
        </div>
        <div class="but cancel" @click="onCancel" v-if="showClose">
          <p class="aqt_lang_mn titlefont">{{'ᠬᠠᠰᠤᠬᠤ'|U2M}}</p>
        </div>
      </div>
    </div>

  </div>
</cube-popup>
</template>

<script>
export default {
  props: {
    option1: {
      type: String,
      default: 'ᠲᠡᠭᠡᠶ᠎ᠡ',
    },
    option2: {
      type: String,
      default: 'ᠲᠡᠭᠡᠶ᠎ᠡ',
    },
    isAlert: {
      type: [Boolean],
      default: false,
    },
    IsShow: {
      type: [Boolean],
      default: false,
    },
    // IsInput: {
    //   type: [Boolean],
    //   default: false,
    // },
    showClose: {
      type: [Boolean],
      default: true,
    },
    message: {
      type: [String],
      default: '',
    },
    //  num: {
    //   type: [Number],
    //   default: null,
    // },
  },
  data() {
    return {
      messagess: '馆藏码错误',
      InputErr: false,
      num: '',
    }
  },
  methods: {
    select1() {
      this.$emit('select1');
    },
    select2() {
      this.$emit('select2');
    },
    onOk() {
      const that = this
      this.$emit('on-ok');
      this.$refs.ActionSheet.hide()
    },
    onCancel() {
      this.$emit('isClose', false);
      this.$refs.ActionSheet.hide()
    },
    oneBuy() {
      this.$emit('one-buy');
      this.$refs.ActionSheet.hide()
    },
    onesBuy() {
      this.$emit('ones-buy');
      this.$refs.ActionSheet.hide()
    },
  },
  computed: {

  },
  mounted() {
    // this.$refs.extendPopup.show() 
  },
  watch: {
    IsShow: function (val) {
      if (this.IsShow) {
        this.$refs.ActionSheet.show()
      } else {
        this.$refs.ActionSheet.hide()
      }
      setTimeout(() => {

      }, 1000)
    },
  }
}
</script>

<style lang="less">
.cube-popup-mask {
  background-color: #25262d !important;
  opacity: .4 !important;
  overflow-x: scroll!important;
  overflow-y: scroll!important;
}

.cube-my-popup1 {
  .cube-popup-content {
    top: -50%;
    left: -37%;
    margin-top: 30px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 15px !important;
    position: relative;
    width: 70%;
    height: 82.5%;
  }

}

#LayActionSheet {
  overflow: hidden;
  writing-mode: horizontal-tb;
  color: #333333;

  .active {
    right: 0;
    animation: identifier 0.4s 1;
    // 解决页面从上往下位移问题

  }

  @keyframes identifier {
    from {
      right: -30rem;
    }

    to {
      right: 0;
    }
  }

  .aqt_confirm_center {
    position: absolute;
    top: 20rem;
    height: 60%;
    display: flex;
    font-size: 2rem;
    border-radius: 1rem;
    // background-color: #ffffff;
    .aqt_confirm_con {
      width: calc(100% - 10rem);
      height: 100%;
      border-right: 1px solid #eeeeee;
      padding: 1rem;
    }

    .aqt_confirm_con1 {
      // width: calc(100% - 10rem);
      height: 100%;
      color: red;
      border-right: 1px solid #eeeeee;
      padding: 1rem;
    }

    .aqt_input {
      //   width: calc(100% - 10rem);
      height: 100%;
      padding: 1rem;
      width: 6rem;
      height: 100%;
      margin: 0 auto;
      //   margin-left: .8rem;

      input {
        border: 1px solid #ccc;
        width: 100%;
        height: 100%;
        display: table-cell;
        color: #000000;
        text-indent: 1rem;
        border-radius: .5rem;
      }

      input.aqt_time {
        height: 48%;
      }
    }

    .aqt_confirm_buton {
      margin-left: 10px;
      // width: 16rem;
      height: 100%;
      float: right;
      display: flex;

      .but {
        width: 5rem;
        height: 90%;
        color: #ffffff;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        display: -ms-flexbox;
        display: flex;
        margin: 10% auto;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          // margin: auto;
        }

        .option {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .but:nth-child(1) {
        border-radius: 1rem 0 0 1rem;
      }

      .ok {
        background-color: var(--themeColor);
        border-right: 1px solid #ccc;
      }

      .cancel {
        background-color: #fff;
        color: #000000;
        border-radius: .5rem;
        margin-left: 5px;
      }

    }
  }

}
</style>
